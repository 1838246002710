import React, { createElement, Fragment, useRef, useEffect } from "react";
import { graphql } from "gatsby";

import remark from "remark";
import remarkAbbr from "remark-abbr";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import rehypeReact from "rehype-react";
import { Helmet } from "react-helmet";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";

import withRoot from "../withRoot";
import Header from "../component/header";

const h2 = withStyles({
  h2: {
    marginTop: "3rem",
    marginBottom: "2rem",
  },
})((props) => (
  <Typography
    color="primary"
    align="center"
    variant="h2"
    component="h2"
    className={props.classes.h2}
  >
    {props.children}
  </Typography>
));

const p = (props) => (
  <Typography align="justify" variant="body1" gutterBottom>
    {props.children}
  </Typography>
);

const ul = (props) => (
  <Typography variant="body1" component="ul">
    {props.children}
  </Typography>
);

const ol = (props) => (
  <Typography variant="body1" component="ol">
    {props.children}
  </Typography>
);

const pre = ({ children, ...others }) => {
  const codeBlockRef = useRef(null);

  useEffect(() => {
    if (typeof document !== undefined && codeBlockRef.current !== null) {
      Prism.highlightElement(codeBlockRef.current);
    }
  }, [codeBlockRef.current]);

  return (
    <pre ref={codeBlockRef} {...others}>
      {children}
    </pre>
  );
};

const blockquote = withStyles({
  root: {
    fontStyle: "italic",
    padding: "1.2em 2rem 1.2em 4rem",
    borderLeft: "8px solid #78C0A8 ",
    position: "relative",
    background: "#EDEDED",
    "&::before": {
      content: "'\\201C'",
      fontFamily: "Arial",
      color: "#78C0A8",
      fontSize: "4em",
      position: "absolute",
      left: "1rem",
      top: "-0.2rem",
    },
    "&::after": {
      content: "",
    },
  },
})((props) => {
  return (
    <blockquote className={props.classes.root}>{props.children}</blockquote>
  );
});

const options = {
  createElement,
  components: {
    h2,
    p,
    ul,
    ol,
    blockquote,
    pre,
  },
};

const BlogPost = ({ data, classes }) => {
  const post = data.markdownRemark;
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {post.frontmatter.title} - Sébastien Viande - Developper FullStack
        </title>
        <meta name="description" content="Personal website, portfolio, blog." />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>
      <Header />
      <Paper id="main" className={classes.main}>
        <Typography color="primary" align="center" variant="h1" gutterBottom>
          {post.frontmatter.title}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          align="center"
          className={classes.info}
        >
          {post.frontmatter.date} · {post.fields.readingTime.text}
        </Typography>
        <section className={classes.section}>
          {
            remark()
              .use(remarkParse)
              .use(remarkRehype)
              .use(rehypeReact, options)
              .processSync(post.rawMarkdownBody).contents
          }
        </section>
      </Paper>
    </Fragment>
  );
};

const styles = ({ spacing, breakpoints }) => ({
  main: {
    position: "relative",
    marginTop: "-5rem",
    marginBottom: "5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    [breakpoints.up("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    padding: spacing(2),
    maxWidth: 800,
  },
  info: {
    color: "grey",
    marginBottom: "2rem",
  },
  section: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    "& img": {
      maxWidth: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
    },
  },
});

export default withRoot(withStyles(styles)(BlogPost));

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      rawMarkdownBody
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`;
